import { Injectable, OnInit } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { AuthService } from '../auth/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";



@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private toastrService: ToastrService

    ) { }

    currentUser: any;


    ngOnInit(): void {
        this.currentUser = this.authService.currentUserValue;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.currentUserValue;
        const basicAuthDetails = this.authService.basicAuthentication;

        if (currentUser) {
            console.log(currentUser);
            request = request.clone({
                setHeaders: {
                    'accessToken': currentUser.accessToken,
                    Authorization: 'Basic ' + btoa(basicAuthDetails.userName + ':' + basicAuthDetails.password)



                }
            })
        }

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                        if (event.body.status == 401) {
                            if (event.body.error.errorCode == 44) {
                                this.router.navigate(['/auth/login']);
                                this.toastrService.error("Session expired please re-login again");
                            } else {
                                // this.router.navigate(['/auth/login']);
                                this.toastrService.error('You are already logged in with different device');
                            }
                        }
                    }
                },
                error => {
                    if (error.status == 503) {
                        if (error.error.errorCode == 101 || error.error.errorCode == 102 || error.error.errorCode == 104) {
                            if (error.error.errorCode == 101) {
                                this.router.navigate(['/auth/login']);
                                this.toastrService.error('You are already logged in with different device');
                            }
                            this.toastrService.error('You are already logged in with different device');
                            this.router.navigate(['/auth/login']);

                        }

                    } else if (error.status == 0) {
                        this.router.navigate(['/auth/login']);
                        this.toastrService.error('No Internet Connection');
                    } else if (error.status == 400) {
                        this.toastrService.error(error?.error?.error?.message);
                    } else {
                        this.toastrService.error('You are already logged in with different device');
                        this.router.navigate(['/auth/login']);
                    }
                }
            )
        );
    }
}