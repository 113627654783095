

export const environment = {
  production: false,



  // API_URL: "https://devapi-palmy.approd.ca/",

  API_URL: "https://api-palmy-stg.approd.ca/",

















};
