import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getDashboard(data: any) {
        return this.http.post(this.url + "appList/getDashboardAnalytics", data);
    }


    getDownloadAppList() {
        return this.http.get(this.url + "appList/getTotalAppsDownloaded");
    }

    getAdminDashboard() {
        return this.http.get(this.url + "api/user/get-admin-dashboard");
    }


    getActiveUsers() {
        return this.http.get(this.url + "appList/getHospitalsUsers");
    }




    getTotalForms() {
        return this.http.get(this.url + "appList/getTotalForms");
    }

    getAgeGroup(data) {
        return this.http.post(this.url + "appList/getAgeGroup", data);
    }

    deviceCount() {
        return this.http.get(this.url + "appList/getDeviceCountByHospital");
    }

    getDeviceModel(id: any) {
        return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
    }

    // experiencerReport(data:any){
    //     return this.http.post(this.url + "user/admin/report", data);
    // }

    // localReport(data:any){
    //     return this.http.post(this.url + "user/admin/report", data);
    // }

    getManageLocalList(data: any) {
        return this.http.post(this.url + "user/admin/get", data)
    }


    getManageLocalById(data: any) {
        return this.http.post(this.url + 'user/get', data)
    }
    // getDashboardCount(data:any){
    //     return this.http.post(this.url + "user/admin/report", data );
    // }

}