import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageCategory {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(data: any) {
        return this.http.post(this.url + 'api/user/upload-image', data);
    }

    addCategory(data: any) {
        return this.http.post(this.url + 'api/coupons/add-business', data);
    }

    categoryList(data: any) {
        return this.http.post(this.url + `api/coupons/get-business`, data);
    }

    updateCategory(data: any) {
        return this.http.post(this.url + 'api/coupons/update-business', data);
    }


    deleteCategory(data: any) {
        return this.http.post(this.url + 'api/coupons/delete-business', data);
    }


}